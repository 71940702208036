import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { testsApi } from '../features/tests/testsApi';
import { profileApi } from '../features/profile/profileApi';
import { dashboardWidgetsApi } from '../features/dashboard/dashboardApi';
import { APP } from '../../_shared/utils/_urls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logout } from '../features/auth/currentUser.slice';
import { UNAUTHORIZED } from '../../_shared/utils/codes';

/**
 * Log a warning and show a toast!
 */
export const rtkUnauthorized: Middleware =
  (api: MiddlewareAPI) => next => action => {
    //const navigate = useNavigate();
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.payload.status === UNAUTHORIZED) {
        api.dispatch(testsApi.util.resetApiState());
        api.dispatch(profileApi.util.resetApiState());
        api.dispatch(dashboardWidgetsApi.util.resetApiState());
        api.dispatch(logout());
        window.location.pathname = APP.LOGIN;
      }
    }

    return next(action);
  };

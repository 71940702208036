import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import createBrowserHistory from 'history/createBrowserHistory';
import storage from 'redux-persist/lib/storage';
import { $CombinedState, combineReducers } from 'redux';
import { authApi } from './features/auth/authApi';
import { testsApi } from './features/tests/testsApi';
import { profileApi } from './features/profile/profileApi';
import { dashboardWidgetsApi } from './features/dashboard/dashboardApi';
import { configureStore } from '@reduxjs/toolkit';
import currentUserReducer from './features/auth/currentUser.slice';
import { checkoutApi } from './features/checkout/checkoutApi';
import { tooltipApi } from './features/tooltip/tooltipApi';
import { schedulingApi } from './features/schedule/scheduleApi';
import { formsApi } from './features/forms/formsApi';
import { rtkUnauthorized } from './middleware/rtqUnauthorized';

export const history = createBrowserHistory();

const persistConfig = {
  key: process.env.REACT_APP_NAME as string,
  storage,
  blacklist: [
    testsApi.reducerPath,
    authApi.reducerPath,
    profileApi.reducerPath,
    dashboardWidgetsApi.reducerPath,
    checkoutApi.reducerPath,
    tooltipApi.reducerPath,
    schedulingApi.reducerPath,
    formsApi.reducerPath,
  ],
};

const reducers = combineReducers({
  [testsApi.reducerPath]: testsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [dashboardWidgetsApi.reducerPath]: dashboardWidgetsApi.reducer,
  [checkoutApi.reducerPath]: checkoutApi.reducer,
  [tooltipApi.reducerPath]: tooltipApi.reducer,
  [schedulingApi.reducerPath]: schedulingApi.reducer,
  [formsApi.reducerPath]: formsApi.reducer,
  currentUserState: currentUserReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      testsApi.middleware,
      authApi.middleware,
      profileApi.middleware,
      dashboardWidgetsApi.middleware,
      checkoutApi.middleware,
      tooltipApi.middleware,
      schedulingApi.middleware,
      formsApi.middleware,
      rtkUnauthorized,
    ]);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
